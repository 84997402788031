<template>
  <vue-bottom-sheet
    ref="itemsAdd"
    :click-to-close="false"
    block
    max-width="90%"
    @opened="mounted = true"
    @closed="mounted = false; resetForm()"
  >
    <div
      class="bottom-sheet pb-2"
      style="height: 90vh"
    >
      <div class="d-flex justify-content-between mb-25 align-content-center px-1 pt-1">
        <h4 class="m-0">
          Tambah Item
        </h4>
        <feather-icon
          icon="XIcon"
          size="28"
          class="text-primary"
          @click="$refs.itemsAdd.close();"
        />
      </div>
      <div class="px-1">
        <h6 class="text-muted font-weight-normal m-0">
          Item ini belum tersedia di database, tambah item ke database terlebih dahulu untuk melengkapi data.
        </h6>
      </div>
      <hr>
      <items-form
        v-if="mounted"
        ref="itemsForm"
        v-model="itemData"
      />
      <div style="height: 20vh;" />
      <div class="p-1 bottom-sheet-footer shadow-lg d-flex">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          class="text-secondary"
          @click="resetForm()"
        >
          Reset
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="ml-auto"
          variant="primary"
          @click="loadingAdd ? null : submitForm()"
        >
          <span v-if="!loadingAdd">Tambah Item</span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </div>
  </vue-bottom-sheet>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemsForm from './ItemsForm.vue'

export default {
  components: {
    VueBottomSheet,
    BSpinner,
    BButton,
    ItemsForm,
  },
  data() {
    return {
      loadingAdd: false,
      mounted: false,
      itemData: {
        id: '',
        data: '',
        vs: 0,
        selectedColor: '',
        packaging: '',
        liter: 0,
        itemType: '',
        agent_item_id: '',
        ratio_agent: 0,
        agent_item_2_id: '',
        ratio_agent_2: 0,
        recommended_thinner: '',
        ratio_recommended_thinner: 5,
      },
      customRedirect: false,
    }
  },
  methods: {
    show(data, redirect) {
      console.log(data)
      this.itemData.id = data.id
      this.itemData.data = data
      this.customRedirect = redirect
      this.$refs.itemsAdd.open()
    },
    submitForm() {
      this.$refs.itemsForm.validate().then(success => {
        if (success) {
          this.loadingAdd = true
          this.$store.dispatch('addItems', {
            id: this.itemData.id,
            vs_volume_solid: this.itemData.vs,
            color_id_ral: this.itemData.selectedColor.id_ral,
            packaging: this.itemData.packaging.id,
            liter: this.itemData.liter,
            agent_item_id: this.itemData.agent_item_id ? this.itemData.agent_item_id.id : null,
            ratio_agent: this.itemData.agent_item_id ? parseFloat(this.itemData.ratio_agent) : null,
            agent_item_2_id: this.itemData.agent_item_2_id ? this.itemData.agent_item_2_id.id : null,
            ratio_agent_2: this.itemData.agent_item_2_id ? parseFloat(this.itemData.ratio_agent_2) : null,
            recommended_thinner_id: this.itemData.recommended_thinner ? this.itemData.recommended_thinner.id : null,
            ratio_recommended_thinner_id: this.itemData.recommended_thinner ? parseFloat(this.itemData.ratio_recommended_thinner) : null,
            item_type: this.itemData.itemType.id,
          }).then(result => {
            this.loadingAdd = false
            if (result.status.toLowerCase() === 'success') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menambah data item!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$refs.itemsAdd.close()

              if (this.customRedirect === 'edit') {
                this.$router.push({ name: 'item-edit', params: { id: this.itemData.id.toString() } })
              } else if (this.customRedirect === 'view') {
                this.$router.push({ name: 'item-detail', params: { id: this.itemData.id.toString() } })
              } else {
                this.$store.dispatch('getAllItems')
              }

              this.$emit('success', this.itemData)
              this.resetForm()
            }
          }).catch(() => {
            this.loadingAdd = false
          })
        }
      })
    },
    resetForm() {
      this.itemData = {
        id: this.itemData.id,
        data: this.itemData.data,
        vs: 0,
        selectedColor: '',
        packaging: '',
        liter: 0,
        itemType: '',
        agent_item_id: '',
        ratio_agent: 0,
        agent_item_2_id: '',
        ratio_agent_2: 0,
        recommended_thinner: '',
        ratio_recommended_thinner: 5,
      }
      this.$refs.itemsForm.reset()
    },
  },
}
</script>

<style lang="scss">
.bottom-sheet-footer {
  width: 100%;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;

  .dark-layout & {
    background-color: #161D31 !important;
  }
}
</style>
