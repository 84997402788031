<template>
  <validation-observer ref="validateItem">
    <b-form @submit.prevent>
      <XyzTransitionGroup
        appear
        class="item-group"
        xyz="fade stagger-2 left-3 delay-2"
        mode="out-in"
      >
        <b-card
          key="1"
          title="Detail Item"
        >
          <b-row>
            <b-col
              v-if="value.data"
              cols="12"
              md="6"
            >
              <small>{{ value.data.no }}</small>
              <h5 class="m-0 mb-1">
                {{ value.data.name }}
              </h5>
            </b-col>
            <b-col
              v-if="value.data"
              cols="12"
              md="6"
              class="d-flex align-items-start mb-1"
            >
              <b-badge variant="light-primary ml-md-auto">
                {{ value.data.itemCategory.name }}
              </b-badge>
            </b-col>
            <b-col
              cols="12"
              xl="6"
            >
              <b-form-group label="Warna">
                <validation-provider
                  #default="{ errors }"
                  name="Warna"
                >
                  <v-select
                    v-model="value.selectedColor"
                    :options="ralColorsSelection"
                    label="eng_name"
                    placeholder="Pilih Warna"
                    :filter="fuseSearch"
                    @option:selected="update('selectedColor', $event)"
                  >
                    <template v-slot:option="item">
                      <div class="d-flex align-items-center">
                        <div
                          class="customswatch mr-50"
                          :style="`background-color: #${item.hex_code};`"
                        />
                        <div>
                          <h6 class="m-0">
                            {{ item.eng_name }}
                          </h6>
                          <small>{{ item.id_ral }}</small>
                        </div>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="6"
            >
              <b-form-group label="Volume Solid">
                <validation-provider
                  #default="{ errors }"
                  name="Volume solid item"
                  rules="min_value:0.01|max_value:100|twodecimal"
                >
                  <custom-spin-input
                    v-model="value.vs"
                    :max="100"
                    :increment="1"
                    class="mb-25"
                    @input="update('vs', $event)"
                  />
                  <small class="text-muted d-block">Minimal : 0.01. Maksimal 100.</small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="6"
            >
              <b-form-group label="Kemasan">
                <validation-provider
                  #default="{ errors }"
                  name="Kemasan item"
                  rules="required"
                >
                  <v-select
                    v-model="value.packaging"
                    :options="packagings"
                    label="name"
                    placeholder="Pilih Packaging"
                    :clearable="false"
                    @option:selected="update('packaging', $event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="6"
            >
              <b-form-group label="Liter">
                <validation-provider
                  #default="{ errors }"
                  name="Jumlah liter item"
                  rules="min_value:0.01|max_value:1000|twodecimal"
                >
                  <custom-spin-input
                    v-model="value.liter"
                    :max="1000"
                    :increment="1"
                    class="mb-25"
                    @input="update('liter', $event)"
                  />
                  <small class="text-muted d-block">Minimal : 0.01. Maksimal 1000.</small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="6"
            >
              <b-form-group label="Tipe">
                <validation-provider
                  #default="{ errors }"
                  name="Tipe item"
                  rules="required"
                >
                  <v-select
                    v-model="value.itemType"
                    :options="itemTypes"
                    label="name"
                    placeholder="Pilih Tipe"
                    :clearable="false"
                    @option:selected="update('itemType', $event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          key="2"
          no-body
        >
          <template #header>
            <div class="w-100 d-flex align-items-center justify-content-between">
              <h4 class="mb-0">
                Agent Item 1
              </h4>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.curing1
                variant="outline-secondary"
              >
                <feather-icon
                  class="ml-auto"
                  size="16"
                  icon="ChevronDownIcon"
                />
              </b-button>
            </div>
          </template>
          <b-collapse
            id="curing1"
          >
            <div class="p-2">
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-1 mb-xl-0"
                >
                  <agent-auto-suggest
                    :value="value.agent_item_id"
                    @onSelected="handleAgent1Selected"
                    @clear="ratio1 = false"
                  />
                </b-col>
                <b-col
                  v-if="ratio1"
                  cols="12"
                  xl="6"
                >
                  <b-collapse :visible="ratio1">
                    <b-form-group
                      label="Ratio"
                      label-for="ratio"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Ratio agent 1"
                        rules="min_value:0.01|max_value:100|twodecimal"
                      >
                        <custom-spin-input
                          v-model="value.ratio_agent"
                          :max="100"
                          :increment="1"
                          class="mb-25"
                          @input="update('ratio_agent', $event)"
                        />
                        <small class="text-muted d-block">Minimal : 0.01. Maksimal 100.</small>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-collapse>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
        </b-card>
        <b-card
          key="3"
          no-body
        >
          <template #header>
            <div class="w-100 d-flex align-items-center justify-content-between">
              <h4 class="mb-0">
                Agent Item 2
              </h4>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.curing2
                variant="outline-secondary"
              >
                <feather-icon
                  class="ml-auto"
                  size="16"
                  icon="ChevronDownIcon"
                />
              </b-button>
            </div>
          </template>
          <b-collapse
            id="curing2"
          >
            <div class="p-2">
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <agent-auto-suggest
                    :value="value.agent_item_2_id"
                    @onSelected="handleAgent2Selected"
                    @clear="ratio2 = false"
                  />
                </b-col>
                <b-col
                  v-if="ratio2"
                  cols="12"
                  xl="6"
                >
                  <b-collapse :visible="ratio2">
                    <b-form-group
                      label="Ratio"
                      label-for="ratio"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Ratio agent 2"
                        rules="min_value:0.01|max_value:100|twodecimal"
                      >
                        <custom-spin-input
                          v-model="value.ratio_agent_2"
                          :max="100"
                          :increment="1"
                          class="mb-25"
                          @input="update('ratio_agent_2', $event)"
                        />
                        <small class="text-muted d-block">Minimal : 0.01. Maksimal 100.</small>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-collapse>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
        </b-card>
        <b-card
          key="4"
          no-body
        >
          <template #header>
            <div class="w-100 d-flex align-items-center justify-content-between">
              <h4 class="mb-0">
                Thinner
              </h4>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.thinner
                variant="outline-secondary"
              >
                <feather-icon
                  class="ml-auto"
                  size="16"
                  icon="ChevronDownIcon"
                />
              </b-button>
            </div>
          </template>
          <b-collapse
            id="thinner"
          >
            <div class="p-2">
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-1 mb-xl-0"
                >
                  <thinner-auto-suggest
                    :value="value.recommended_thinner"
                    @onSelected="update('recommended_thinner', $event); thinner = true"
                    @clear="thinner = false"
                  />
                </b-col>
                <b-col
                  v-if="thinner"
                  cols="12"
                  xl="6"
                >
                  <b-collapse :visible="thinner">
                    <b-form-group
                      label="Ratio"
                      label-for="ratio"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Ratio thinner"
                        rules="min_value:0.01|max_value:100|twodecimal"
                      >
                        <custom-spin-input
                          v-model="value.ratio_recommended_thinner"
                          :max="10"
                          :increment="1"
                          class="mb-25"
                          @input="update('ratio_recommended_thinner', $event)"
                        />
                        <small class="text-muted d-block">Minimal : 0.01. Maksimal 10.</small>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-collapse>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
        </b-card>
        <b-card key="5">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <h4>
              Data Sheet
            </h4>
            <b-button
              v-if="value.data.data_sheet"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="outline-danger"
              @click="$refs.pdf.show(value.data.data_sheet)"
            >
              <b-img
                :src="require('@/assets/images/icons/pdf.png')"
                height="auto"
                width="16"
                class="mr-25"
              />
              Preview
            </b-button>
          </div>
          <vue-dropzone
            id="dropzone"
            ref="dropDataSheet"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            @vdropzone-error="dokumenError"
            @vdropzone-mounted="dropzoneMounted"
            @vdropzone-file-added="removePreviousFile(); completeUpload($event)"
            @vdropzone-removed-file="(file, error, xhr) => removeFile('dropTtd', file, error,xhr)"
          >
            <div class="d-flex align-items-center justify-content-center h-100 pt-50">
              <h6 class="text-muted font-weight-normal mt-2">
                Upload PDF
              </h6>
            </div>
          </vue-dropzone>
        </b-card>
      </XyzTransitionGroup>
    </b-form>
    <pdf-preview
      v-if="value.data.data_sheet"
      ref="pdf"
    />
  </validation-observer>
</template>

<script>
// eslint-disable no-unused-expressions

import {
  BCard, BForm, BRow, BCol, BFormGroup, BButton, VBToggle, BCollapse, BImg, BBadge,
} from 'bootstrap-vue'
import AgentAutoSuggest from '@/components/input/AgentAutoSuggest.vue'
import ThinnerAutoSuggest from '@/components/input/ThinnerAutoSuggest.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import Fuse from 'fuse.js'
import {
  required, minValue, maxValue,
} from '@validations'
import CustomSpinInput from '@/components/input/CustomSpinInput.vue'
import VueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PdfPreview from '@/components/misc/PdfPreview.vue'

export default {
  components: {
    BCard,
    AgentAutoSuggest,
    ThinnerAutoSuggest,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCollapse,
    CustomSpinInput,
    BBadge,
    BImg,
    VueDropzone,
    PdfPreview,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      ratio1: false,
      ratio2: false,
      thinner: false,
      required,
      minValue,
      maxValue,
      loadingDetail: true,
      loadingAdd: false,
      uploadFile: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        autoProcessQueue: true,
        acceptedFiles: '.pdf',
        maxFilesize: 5,
        dictDefaultMessage: 'Taruh File Disini',
        uploadMultiple: false,
        maxFiles: 1,
        dictCancelUpload: 'Batalkan',
        dictCancelUploadConfirmation: 'Apakah anda yakin?',
        dictRemoveFile: 'Hapus File',
        dictFileTooBig: 'File terlalu besar ({{filesize}}MB). Maximal ukuran: {{maxFilesize}}MB.',
        dictInvalidFileType: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .pdf',
        dictMaxFilesExceeded: 'Tidak boleh mengupload lebih dari 1 file. Hapus file sebelumnya terlebih dahulu.',
      },
    }
  },
  computed: {
    ...mapGetters({
      ralColorsSelection: 'getColors',
      itemTypes: 'getItemTypes',
      packagings: 'getPackagingsList',
    }),
  },
  async mounted() {
    // eslint-disable-next-line no-unused-expressions
    this.value.agent_item_id !== '' ? this.ratio1 = true : this.ratio1 = false
    // eslint-disable-next-line no-unused-expressions
    this.value.agent_item_2_id !== '' ? this.ratio2 = true : this.ratio2 = false
    // eslint-disable-next-line no-unused-expressions
    this.value.recommended_thinner !== '' ? this.thinner = true : this.thinner = false
    await this.getColors()
    await this.getTypes()
    this.update('itemType', this.itemTypes[0])
    await this.getPackagings()
    this.update('packaging', this.packagings[0])
  },
  methods: {
    ...mapActions(['getColors', 'getTypes', 'getPackagings']),
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['id_ral', 'hex_code', 'ind_name', 'eng_name'],
        shouldSort: true,
        findAllMatches: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item).slice(0, 10)
        : fuse.list
    },
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    handleAgent1Selected(data) {
      this.update('agent_item_id', data)

      this.$store.dispatch('getItemsDetail', data.id).then(result => {
        if (result.unit2name === 'BH' || result.unit2name === 'SET') {
          this.update('ratio_agent', result.ratio2)
        }

        if (result.unit3name === 'BH' || result.unit3name === 'SET') {
          this.update('ratio_agent', result.ratio3)
        }
        this.ratio1 = true
      })
    },
    handleAgent2Selected(data) {
      this.update('agent_item_2_id', data)

      this.$store.dispatch('getItemsDetail', data.id).then(result => {
        if (result.unit2name === 'BH' || result.unit2name === 'SET') {
          this.update('ratio_agent_2', result.ratio2)
        }

        if (result.unit3name === 'BH' || result.unit3name === 'SET') {
          this.update('ratio_agent_2', result.ratio3)
        }
        this.ratio2 = true
      })
    },
    dropzoneMounted() {
      this.$refs.dropDataSheet.dropzone.files.forEach(el => {
        this.$refs.dropDataSheet.removeFile(el)
      })
      if (this.value.data.data_sheet) {
        const filename = this.value.data.data_sheet.substring(this.value.data.data_sheet.lastIndexOf('/') + 1)
        const file = {
          name: filename, size: 5000000, type: 'application/pdf', accepted: true,
        }
        this.$refs.dropDataSheet.manuallyAddFile(file, this.value.data.data_sheet)
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.validateItem.validate().then(async success => {
          if (success) {
            resolve(true)
            if (this.uploadFile && this.$refs.dropDataSheet.getAcceptedFiles().length) {
              await this.$store.dispatch('uploadDataSheet', {
                item_id: +this.value.data.id,
                file: this.$refs.dropDataSheet.getAcceptedFiles()[0],
              })
            } else if (this.uploadFile && !this.$refs.dropDataSheet.dropzone.files.length) {
              await this.$store.dispatch('removeDataSheet', {
                item_id: +this.value.data.id,
              })
            }
            this.uploadFile = false
          } else {
            /* eslint-disable prefer-promise-reject-errors */
            reject(false)
          }
        })
      })
    },
    async removeFile() {
      this.uploadFile = true
    },
    removePreviousFile() {
      const photo = ['pdf', 'PDF']
      if (this.$refs.dropDataSheet.dropzone.files.length > 1) {
        this.$refs.dropDataSheet.removeFile(this.$refs.dropDataSheet.dropzone.files[0])
      }
      const filename = this.$refs.dropDataSheet.dropzone.files[0]?.upload?.filename.split('.')
      const format = filename?.length ? filename[filename.length - 1] : null
      if (!photo.includes(format) && filename) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .pdf',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.$refs.dropDataSheet.removeFile(this.$refs.dropDataSheet.dropzone.files[0])
      }
      if (this.$refs.dropDataSheet.dropzone.files[0]?.upload?.total >= 5000000) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `File terlalu besar (${this.$refs.dropDataSheet.dropzone.files[0]?.upload?.total / 1000000} MB). Maximal ukuran: ${5000000 / 1000000} MB.`,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.$refs.dropDataSheet.removeFile(this.$refs.dropDataSheet.dropzone.files[0])
      }
    },
    completeUpload(data) {
      if (!data.manuallyAdded) {
        this.uploadFile = true
      } else {
        this.uploadFile = false
      }
    },

    reset() {
      this.$refs.validateItem.reset()
    },
    dokumenError(file, message) {
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dz-progress {
  display: none;
}
.dropzone {
  padding: 0 !important;

  .dark-layout & {
    background: #283046;
  }
}
.vue-dropzone {
  border-radius: 4px;
  border: 1px solid #d8d6de;

  .dark-layout & {
    border: 1px solid #404656;
  }
}
</style>

<style scoped>
.customswatch{
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
</style>
