var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validateItem"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('XyzTransitionGroup',{staticClass:"item-group",attrs:{"appear":"","xyz":"fade stagger-2 left-3 delay-2","mode":"out-in"}},[_c('b-card',{key:"1",attrs:{"title":"Detail Item"}},[_c('b-row',[(_vm.value.data)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('small',[_vm._v(_vm._s(_vm.value.data.no))]),_c('h5',{staticClass:"m-0 mb-1"},[_vm._v(" "+_vm._s(_vm.value.data.name)+" ")])]):_vm._e(),(_vm.value.data)?_c('b-col',{staticClass:"d-flex align-items-start mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-badge',{attrs:{"variant":"light-primary ml-md-auto"}},[_vm._v(" "+_vm._s(_vm.value.data.itemCategory.name)+" ")])],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Warna"}},[_c('validation-provider',{attrs:{"name":"Warna"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.ralColorsSelection,"label":"eng_name","placeholder":"Pilih Warna","filter":_vm.fuseSearch},on:{"option:selected":function($event){return _vm.update('selectedColor', $event)}},scopedSlots:_vm._u([{key:"option",fn:function(item){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"customswatch mr-50",style:(("background-color: #" + (item.hex_code) + ";"))}),_c('div',[_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(item.eng_name)+" ")]),_c('small',[_vm._v(_vm._s(item.id_ral))])])])]}}],null,true),model:{value:(_vm.value.selectedColor),callback:function ($$v) {_vm.$set(_vm.value, "selectedColor", $$v)},expression:"value.selectedColor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Volume Solid"}},[_c('validation-provider',{attrs:{"name":"Volume solid item","rules":"min_value:0.01|max_value:100|twodecimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-spin-input',{staticClass:"mb-25",attrs:{"max":100,"increment":1},on:{"input":function($event){return _vm.update('vs', $event)}},model:{value:(_vm.value.vs),callback:function ($$v) {_vm.$set(_vm.value, "vs", $$v)},expression:"value.vs"}}),_c('small',{staticClass:"text-muted d-block"},[_vm._v("Minimal : 0.01. Maksimal 100.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Kemasan"}},[_c('validation-provider',{attrs:{"name":"Kemasan item","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.packagings,"label":"name","placeholder":"Pilih Packaging","clearable":false},on:{"option:selected":function($event){return _vm.update('packaging', $event)}},model:{value:(_vm.value.packaging),callback:function ($$v) {_vm.$set(_vm.value, "packaging", $$v)},expression:"value.packaging"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Liter"}},[_c('validation-provider',{attrs:{"name":"Jumlah liter item","rules":"min_value:0.01|max_value:1000|twodecimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-spin-input',{staticClass:"mb-25",attrs:{"max":1000,"increment":1},on:{"input":function($event){return _vm.update('liter', $event)}},model:{value:(_vm.value.liter),callback:function ($$v) {_vm.$set(_vm.value, "liter", $$v)},expression:"value.liter"}}),_c('small',{staticClass:"text-muted d-block"},[_vm._v("Minimal : 0.01. Maksimal 1000.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Tipe"}},[_c('validation-provider',{attrs:{"name":"Tipe item","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.itemTypes,"label":"name","placeholder":"Pilih Tipe","clearable":false},on:{"option:selected":function($event){return _vm.update('itemType', $event)}},model:{value:(_vm.value.itemType),callback:function ($$v) {_vm.$set(_vm.value, "itemType", $$v)},expression:"value.itemType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',{key:"2",attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Agent Item 1 ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-toggle",rawName:"v-b-toggle.curing1",modifiers:{"curing1":true}}],attrs:{"variant":"outline-secondary"}},[_c('feather-icon',{staticClass:"ml-auto",attrs:{"size":"16","icon":"ChevronDownIcon"}})],1)],1)]},proxy:true}])},[_c('b-collapse',{attrs:{"id":"curing1"}},[_c('div',{staticClass:"p-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-1 mb-xl-0",attrs:{"cols":"12","xl":"6"}},[_c('agent-auto-suggest',{attrs:{"value":_vm.value.agent_item_id},on:{"onSelected":_vm.handleAgent1Selected,"clear":function($event){_vm.ratio1 = false}}})],1),(_vm.ratio1)?_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-collapse',{attrs:{"visible":_vm.ratio1}},[_c('b-form-group',{attrs:{"label":"Ratio","label-for":"ratio"}},[_c('validation-provider',{attrs:{"name":"Ratio agent 1","rules":"min_value:0.01|max_value:100|twodecimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-spin-input',{staticClass:"mb-25",attrs:{"max":100,"increment":1},on:{"input":function($event){return _vm.update('ratio_agent', $event)}},model:{value:(_vm.value.ratio_agent),callback:function ($$v) {_vm.$set(_vm.value, "ratio_agent", $$v)},expression:"value.ratio_agent"}}),_c('small',{staticClass:"text-muted d-block"},[_vm._v("Minimal : 0.01. Maksimal 100.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3649689846)})],1)],1)],1):_vm._e()],1)],1)])],1),_c('b-card',{key:"3",attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Agent Item 2 ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-toggle",rawName:"v-b-toggle.curing2",modifiers:{"curing2":true}}],attrs:{"variant":"outline-secondary"}},[_c('feather-icon',{staticClass:"ml-auto",attrs:{"size":"16","icon":"ChevronDownIcon"}})],1)],1)]},proxy:true}])},[_c('b-collapse',{attrs:{"id":"curing2"}},[_c('div',{staticClass:"p-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('agent-auto-suggest',{attrs:{"value":_vm.value.agent_item_2_id},on:{"onSelected":_vm.handleAgent2Selected,"clear":function($event){_vm.ratio2 = false}}})],1),(_vm.ratio2)?_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-collapse',{attrs:{"visible":_vm.ratio2}},[_c('b-form-group',{attrs:{"label":"Ratio","label-for":"ratio"}},[_c('validation-provider',{attrs:{"name":"Ratio agent 2","rules":"min_value:0.01|max_value:100|twodecimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-spin-input',{staticClass:"mb-25",attrs:{"max":100,"increment":1},on:{"input":function($event){return _vm.update('ratio_agent_2', $event)}},model:{value:(_vm.value.ratio_agent_2),callback:function ($$v) {_vm.$set(_vm.value, "ratio_agent_2", $$v)},expression:"value.ratio_agent_2"}}),_c('small',{staticClass:"text-muted d-block"},[_vm._v("Minimal : 0.01. Maksimal 100.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2589438070)})],1)],1)],1):_vm._e()],1)],1)])],1),_c('b-card',{key:"4",attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Thinner ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-toggle",rawName:"v-b-toggle.thinner",modifiers:{"thinner":true}}],attrs:{"variant":"outline-secondary"}},[_c('feather-icon',{staticClass:"ml-auto",attrs:{"size":"16","icon":"ChevronDownIcon"}})],1)],1)]},proxy:true}])},[_c('b-collapse',{attrs:{"id":"thinner"}},[_c('div',{staticClass:"p-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-1 mb-xl-0",attrs:{"cols":"12","xl":"6"}},[_c('thinner-auto-suggest',{attrs:{"value":_vm.value.recommended_thinner},on:{"onSelected":function($event){_vm.update('recommended_thinner', $event); _vm.thinner = true},"clear":function($event){_vm.thinner = false}}})],1),(_vm.thinner)?_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-collapse',{attrs:{"visible":_vm.thinner}},[_c('b-form-group',{attrs:{"label":"Ratio","label-for":"ratio"}},[_c('validation-provider',{attrs:{"name":"Ratio thinner","rules":"min_value:0.01|max_value:100|twodecimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-spin-input',{staticClass:"mb-25",attrs:{"max":10,"increment":1},on:{"input":function($event){return _vm.update('ratio_recommended_thinner', $event)}},model:{value:(_vm.value.ratio_recommended_thinner),callback:function ($$v) {_vm.$set(_vm.value, "ratio_recommended_thinner", $$v)},expression:"value.ratio_recommended_thinner"}}),_c('small',{staticClass:"text-muted d-block"},[_vm._v("Minimal : 0.01. Maksimal 10.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3775425974)})],1)],1)],1):_vm._e()],1)],1)])],1),_c('b-card',{key:"5"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('h4',[_vm._v(" Data Sheet ")]),(_vm.value.data.data_sheet)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.$refs.pdf.show(_vm.value.data.data_sheet)}}},[_c('b-img',{staticClass:"mr-25",attrs:{"src":require('@/assets/images/icons/pdf.png'),"height":"auto","width":"16"}}),_vm._v(" Preview ")],1):_vm._e()],1),_c('vue-dropzone',{ref:"dropDataSheet",attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"use-custom-slot":true},on:{"vdropzone-error":_vm.dokumenError,"vdropzone-mounted":_vm.dropzoneMounted,"vdropzone-file-added":function($event){_vm.removePreviousFile(); _vm.completeUpload($event)},"vdropzone-removed-file":function (file, error, xhr) { return _vm.removeFile('dropTtd', file, error,xhr); }}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100 pt-50"},[_c('h6',{staticClass:"text-muted font-weight-normal mt-2"},[_vm._v(" Upload PDF ")])])])],1)],1)],1),(_vm.value.data.data_sheet)?_c('pdf-preview',{ref:"pdf"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }