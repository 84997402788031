<template>
  <div>
    <!-- eslint-disable vue/attribute-hyphenation -->
    <vue-good-table
      ref="vgt"
      :columns="columns"
      :rows="data"
      :pagination-options="{
        enabled: true,
        perPage: filterItems.limit,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: {type: filterItems.selectedSort.sortType, field:filterItems.selectedSort.field},
      }"
      styleClass="vgt-table"
    >
      <empty-state-table slot="emptystate" />
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'name'">
          <div class="d-flex">
            <div
              v-b-popover.hover.top="props.row.isInDB ? 'Tersedia di database' : 'Tidak tersedia di database'"
              class="box-status mr-50"
              :class="props.row.isInDB ? 'bg-success' : 'bg-danger'"
            />
            <div>
              <router-link
                v-if="props.row.isInDB"
                :to="{ name: 'item-detail', params: {id: props.row.id.toString()} }"
              >
                <h6 class="text-primary m-0">{{ props.row.name }}</h6>
              </router-link>
              <h6
                v-else
                class="m-0"
                :class="{ 'cursor-pointer' : permission.itemForm }"
                @click="permission.itemForm ? $refs.addItem.show(props.row, 'view') : null"
              >
                {{ props.row.name }}
              </h6>
              <small>{{ props.row.no }} {{ props.row.liter ? `(${props.row.liter} Liter)` : '' }}</small>
            </div>
          </div>
        </span>
        <span v-if="props.column.field === 'no'">
          <!-- <div class="d-flex">
            <div
              v-b-popover.hover.top="props.row.isInDB ? 'Tersedia di database' : 'Tidak tersedia di database'"
              class="box-status mr-50"
              :class="props.row.isInDB ? 'bg-success' : 'bg-danger'"
            />
            <div>
              <router-link
                v-if="props.row.isInDB"
                :to="{ name: 'item-detail', params: {id: props.row.id.toString()} }"
              >
                <h6 class="text-primary m-0">{{ props.row.name }}</h6>
              </router-link>
              <h6
                v-else
                class="m-0"
                :class="{ 'cursor-pointer' : permission.itemForm }"
                @click="permission.itemForm ? $refs.addItem.show(props.row, 'view') : null"
              >
                {{ props.row.name }}
              </h6>
              <small>{{ props.row.no }} {{ props.row.liter ? `(${props.row.liter} Liter)` : '' }}</small>
            </div>
          </div> -->
        </span>
        <span v-else-if="props.column.field === 'itemCategory'">
          <h6 class="m-0">{{ props.row.itemCategory.name }}</h6>
        </span>
        <span v-else-if="props.column.field === 'type'">
          <h6 class="m-0">{{ props.row.type.name }}</h6>
        </span>
        <span v-else-if="props.column.field === 'color'">
          <div
            v-if="props.row.color"
            class="d-flex align-items-center"
          >
            <div
              v-b-popover.hover.top="'#'+props.row.color.hex_code"
              class="colorswatch mr-50"
              :style="`background-color: #${props.row.color.hex_code};`"
            />
            <div>
              <h6 class="m-0">{{ props.row.color.eng_name }}</h6>
              <small>{{ props.row.color.id_ral }}</small>
            </div>
          </div>
        </span>
        <span v-else-if="props.column.field === 'balance'">
          <h6
            v-if="props.row.balance > 0"
            class="m-0"
          >Balance : {{ props.row.balance }}</h6>
          <small v-if="props.row.availableToSell > 0">Tersedia : {{ props.row.availableToSell }}</small>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              offset="-100"
              boundary="window"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :disabled="!props.row.isInDB"
                @click="props.row.isInDB ? $router.push({ name: 'item-detail', params: { id: props.row.id.toString() }}) : $refs.addItem.show(props.row, 'view')"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                  :class="props.row.isInDB ? 'text-primary' : 'text-muted'"
                />
                <span :class="props.row.isInDB ? 'text-primary' : 'text-muted'">Lihat Item</span>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                :disabled="!permission.itemForm"
                @click="props.row.isInDB ? $router.push({ name: 'item-edit', params: { id: props.row.id.toString() }}) : $refs.addItem.show(props.row, 'edit')"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                  :class="permission.itemForm ? 'text-warning' : 'text-muted'"
                />
                <span :class="permission.itemForm ? 'text-warning' : 'text-muted'">Edit Item</span>
              </b-dropdown-item>
              <b-dropdown-divider v-if="!props.row.isInDB && permission.itemForm" />
              <b-dropdown-item
                v-if="!props.row.isInDB && permission.itemForm"
                @click="$refs.addItem.show(props.row)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50 text-primary"
                />
                <span class="text-primary">Tambah ke Database</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Lihat 1 hingga
            </span>
            <b-form-select
              v-model="filterItems.limit"
              :options="perPageOptions"
              class="mx-1"
            />
            <span class="text-nowrap "> dari total {{ itemCount }} data </span>
          </div>
          <div>
            <b-pagination
              v-model="filterItems.offset"
              :total-rows="itemCount"
              :per-page="filterItems.limit"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <items-add
      ref="addItem"
    />
  </div>
</template>

<script>
import {
  BFormSelect, BPagination, BDropdown, BDropdownItem, VBPopover, BDropdownDivider,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import { createHelpers } from 'vuex-map-fields'
import ItemsAdd from './ItemsAdd.vue'

const { mapFields } = createHelpers({
  getterType: 'getItemsField',
  mutationType: 'updateItemsField',
})

export default {
  components: {
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    VueGoodTable,
    EmptyStateTable,
    ItemsAdd,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mounted: false,
      limitPage: 25,
      perPageOptions: [25, 50, 100, 200],
      columns: [
        {
          field: 'name',
          label: 'Item',
          sortable: false,
          tdClass: 'w-vgt-280',
        },
        {
          field: 'no',
          sortable: false,
        },
        {
          field: 'itemCategory',
          label: 'Kategori',
          sortable: false,
        },
        {
          field: 'type',
          label: 'Tipe',
          sortable: false,
        },
        {
          field: 'vs_volume_solid',
          label: 'VS',
          sortable: false,
        },
        {
          field: 'color',
          label: 'Warna',
          sortable: false,
          width: '180px',
        },
        {
          field: 'balance',
          label: 'Balance',
          sortable: false,
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
      itemCount: 'getItemsCount',
    }),
    ...mapFields([
      'filterItems',
    ]),
  },
  mounted() {
    this.$refs.vgt.currentPage = this.filterItems.offset
  },
  methods: {
    ...mapActions(['deleteProject']),
    // handleSort(data) {
    //   // eslint-disable-next-line prefer-destructuring
    //   this.filterItems.selectedSort = data[0]
    // },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
.colorswatch {
  height: 36px;
  width: 36px;
  border-radius: 4px;
}
.box-status {
  height: 40px;
  width: 6px;
  border-radius: 2px;
}
</style>
