<template>
  <div class="d-flex flex-column">
    <vue-autosuggest
      v-model="selected"
      :suggestions="filteredOptions"
      :input-props="inputProps"
      :limit="10"
      :component-attr-id-autosuggest="filteredOptions.length && filteredOptions[0].data[0] === 'empty' ? 'AgentEmpty' : 'AutoSuggestItem'"
      @selected="onSelected"
      @input="onInputChange"
    >
      <template slot="before-suggestions">
        <div
          v-if="filteredOptions.length && filteredOptions[0].data[0] === 'empty'"
          class="d-flex justify-content-center align-items-center p-1"
          @mouseup.stop
        >
          <h6 class="text-muted font-weight-normal m-0">
            Agent tidak ditemukan
          </h6>
        </div>
      </template>
      <template slot="before-input">
        <div class="d-flex flex-wrap justify-content-between">
          <label>Cari Agent</label>
          <b-spinner
            v-if="loadingSearch && !error"
            class="mr-25"
            small
            variant="primary"
          />
          <label
            v-if="error"
            class="text-danger"
          >
            Data agent sedang bermasalah 😞
          </label>
        </div>
      </template>
      <template slot-scope="{suggestion}">
        <div
          v-if="filteredOptions.length && filteredOptions[0].data[0] !== 'empty'"
          class="d-flex align-items-center cursor-pointer"
        >
          <b-avatar
            :style="suggestion.item.type.id === 1 ? `background: #${resolveColor(suggestion.item.color)} !important;` : ''"
            :variant="suggestion.item.type.id === 1 ? 'light-primary' : suggestion.item.type.id === 2 ? 'secondary' : 'light-secondary'"
            :icon="suggestion.item.type.id === 1 ? 'circle' : null"
            :text="suggestion.item.type.id === 1 ? '' : suggestion.item.type.id === 2 ? 'AG' : 'TH'"
          />
          <div class="ml-50">
            <h6 class="m-0">
              {{ suggestion.item.name }}
            </h6>
            <small>{{ suggestion.item.no }} - {{ suggestion.item.itemCategory.name }} {{ suggestion.item.liter ? `- ${suggestion.item.liter} Liter` : null }}</small>
          </div>
        </div>
      </template>
    </vue-autosuggest>
    <b-button
      v-show="selected"
      size="sm"
      variant="link"
      class="text-primary align-self-end"
      @click="selected = null; $emit('clear')"
    >
      clear
    </b-button>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { BAvatar, BSpinner, BButton } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { items } from '@/graphql/query'

export default {
  components: {
    VueAutosuggest,
    BAvatar,
    BSpinner,
    BButton,
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: () => {},
    },
  },
  data() {
    return {
      loadingSearch: false,
      avatarText,
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Cari agent...',
      },
      selected: '',
      limit: 10,
      error: false,
    }
  },
  mounted() {
    if (this.value && this.value.name) {
      this.selected = this.value.name
    }
  },
  methods: {
    resolveColor(color) {
      if (color) {
        return color.hex_code ? color.hex_code : '#FFF'
      }

      return '#FFF'
    },
    onSelected(option) {
      if (option) {
        this.$emit('onSelected', option.item)
      }
    },
    onInputChange(text) {
      this.error = false
      if (text.length > 2) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async () => {
          this.loadingSearch = true
          await this.$apollo.query({
            query: items,
            context: {
              headers: {
                Authorization: this.$store.getters.getBearerToken,
              },
            },
            variables: {
              filter: {
                search: text.toLowerCase(),
                item_type: 2,
              },
            },
            fetchPolicy: 'no-cache',
          }).then(result => {
            this.filteredOptions = [{
              data: result.data.items.items.length ? result.data.items.items : ['empty'],
            }]

            this.loadingSearch = false
          }).catch(() => {
            this.loadingSearch = false
            this.error = true
          })
        }, 1000)
      } else {
        this.filteredOptions = [{
          data: [],
        }]

        this.loadingSearch = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

#AgentEmpty #autosuggest__results-item--0 {
  display: none !important;
}
</style>
