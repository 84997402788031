<template>
  <div>
    <XyzTransitionGroup
      appear
      class="item-group"
      xyz="fade left-3"
    >
      <b-card key="1">
        <XyzTransition
          appear
          xyz="fade up-3 delay-7"
        >
          <b-row
            class="mb-1"
          >
            <b-col
              cols="12"
              md="3"
              class="mb-1"
            >
              <b-form-input
                v-model="filterItems.search"
                placeholder="Cari item"
                debounce="500"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1"
            >
              <v-select
                v-model="filterItems.selectedSort"
                class="mr-1 w-100"
                label="label"
                :options="filterItems.sortOptions"
                placeholder="Sortir"
                :clearable="false"
                @option:selected="$store.dispatch('getAllItems')"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar.sidebar-right
                variant="outline-primary"
                class="mr-1"
                block
              >
                <feather-icon
                  icon="FilterIcon"
                  size="16"
                />
                Filter
              </b-button>
            </b-col>
            <b-col
              v-if="filterMode"
              cols="12"
              class="mb-50"
            >
              <b-badge
                variant="primary"
                class="align-self-center mr-50 cursor-pointer"
                @click="resetFilter"
              >
                Reset Filter
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>
            </b-col>
          </b-row>
        </XyzTransition>
        <XyzTransition
          appear
          xyz="fade down-3 delay-5"
        >
          <b-overlay
            :show="loading"
          >
            <items-table
              :data="items"
            />
          </b-overlay>
        </XyzTransition>
      </b-card>
    </XyzTransitionGroup>

    <b-sidebar
      id="sidebar-right"
      ref="sidebarRight"
      title="Filter"
      right
      shadow
      backdrop
      backdrop-variant="transparent"
      class="sidebar"
      @hidden="$store.dispatch('getAllItems')"
      @shown="fetchSomeStuff()"
    >
      <div class="px-2 py-1">
        <label>Kategori Item</label>
        <v-select
          v-model="filterItems.category"
          class="w-100 mb-1"
          :options="itemCategory"
          label="name"
          placeholder="Pilih kategori"
        />
        <label>Tipe Item</label>
        <v-select
          v-model="filterItems.item_type"
          class="w-100"
          :options="type"
          multiple
          label="name"
          placeholder="Pilih tipe"
        />
      </div>
      <template #footer>
        <div class="px-2 py-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="secondary"
            class="mt-auto"
            @click="resetFilter()"
          >
            Reset Filter
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <ItemsAdd ref="addItems" />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BOverlay, BButton, VBToggle, BSidebar, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { createHelpers } from 'vuex-map-fields'
import ItemsTable from './ItemsTable.vue'
import ItemsAdd from './ItemsAdd.vue'

const { mapFields } = createHelpers({
  getterType: 'getItemsField',
  mutationType: 'updateItemsField',
})

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BFormInput,
    BButton,
    BSidebar,
    BBadge,
    ItemsTable,
    vSelect,
    ItemsAdd,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapFields([
      'filterItems',
    ]),
    ...mapGetters({
      items: 'getItems',
      itemCount: 'getItemsCount',
      itemCategory: 'getItemCategory',
      loading: 'getLoadingItems',
      type: 'getItemTypes',
    }),
    filterMode() {
      if (this.filterItems.search) {
        return true
      }

      if (this.filterItems.category) {
        return true
      }
      if (this.filterItems.item_type.length) {
        return true
      }

      return false
    },
  },
  watch: {
    filterItems: {
      handler() {
        if (!this.$refs.sidebarRight.isOpen) {
          this.$store.dispatch('getAllItems')
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('getTypes')
    if (this.items.length < 1) {
      this.$store.dispatch('getAllItems')
    }
    console.log(this.data)
    if (this.data?.id) {
      this.$refs.addItems.show(this.data)
    }
  },
  methods: {
    fetchSomeStuff() {
      if (this.itemCategory.length < 1) {
        this.$store.dispatch('getCategories')
      }
    },
    handleSort(data) {
      // eslint-disable-next-line prefer-destructuring
      this.filterItems.selectedSort = data[0]
    },
    resetFilter() {
      this.category = ''
      this.filterItems.offset = 1
      this.filterItems.search = ''
      this.filterItems.category = ''
      this.filterItems.item_type = []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.b-sidebar {
  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  width: 360px;
}
</style>
